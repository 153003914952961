import Head from 'next/head';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NotFound from 'layouts/main/NotFound';
import TemplateMain from 'templates/TemplateMain';

const DiscussionsPage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t('pages.notFound.404.title')} | SecBio</title>
      </Head>

      <TemplateMain>
        <NotFound error={404} />
      </TemplateMain>
    </>
  );
};
export default DiscussionsPage;
